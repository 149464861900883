
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "styles/akk-palette";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$akkportal-primary: mat.define-palette($md-akkprimary);
$akkportal-accent: mat.define-palette($md-akksecondary, A200, A100, A400);

.original-akk-color {
  color: #ff662b;
  //color: #F07D00 #ff662b #ff5722
}
// The warn palette is optional (defaults to red).
$akkportal-warn: mat.define-palette(mat.$red-palette);


//@font-face {
//  font-family: 'custom-font';
//  src: url('assets/custom-font.ttf');
//}
//$custom-typography: mat.define-typography-config(
//  $font-family: "custom-font"
//);

$akk-typography: mat.define-typography-config(
  $font-family: Inter,
  $headline-1:     mat.define-typography-level(36px, 44px, 500, $letter-spacing: -0.02em),
  $headline-2:     mat.define-typography-level(36px, 44px, 500, $letter-spacing: -0.02em),
  $headline-3:     mat.define-typography-level(32px, 38.4px, 500, $letter-spacing: -0.02em),
  $headline-4:     mat.define-typography-level(28px, 35px, 500, $letter-spacing: -0.02em),
  $headline-5:      mat.define-typography-level(24px, 28.8px, 500, $letter-spacing: -0.02em),
  $headline-6:         mat.define-typography-level(18px, 24.3px, 500, $letter-spacing: -0.01em),
  $subtitle-1:  mat.define-typography-level(16px, 19.2px, 500, $letter-spacing: 0em),
  $subtitle-2:  mat.define-typography-level(15px, 18px, 500, $letter-spacing: -0.02em),
  //$body-2:        mat.define-typography-level(20px, 28px, 400, $letter-spacing: 0em),
  //$body-1:        mat.define-typography-level(18px, 25.2px, 400, $letter-spacing: 0em),
  $body-2:        mat.define-typography-level(18px, 25.2px, 400, $letter-spacing: 0em),
  $body-1:        mat.define-typography-level(16px, 24px, 400, $letter-spacing: 0em),
  $caption:       mat.define-typography-level(14px, 21px, 400, $letter-spacing: 0em),
  $button:        mat.define-typography-level(16px, 19.2px, 500, $letter-spacing: 0em),
    // Line-height must be unit-less fraction of the font-size.
  $overline:         mat.define-typography-level(inherit, 1.125, 400)
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$akkportal-theme: mat.define-light-theme((

  color: (
    primary: $akkportal-primary,
    accent: $akkportal-accent,
    warn: $akkportal-warn,
  ),
  typography: $akk-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($akkportal-theme);
@include mat.typography-hierarchy($akkportal-theme);


$akk-typography-chips: mat.define-typography-config(
  $font-family: Inter,
  $body-2:        mat.define-typography-level(16px, 24px, 500, $letter-spacing: 0em),
  $body-1:        mat.define-typography-level(16px, 24px, 400, $letter-spacing: 0em),
);
@include mat.chips-typography($akk-typography-chips);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Inter, "Helvetica Neue", sans-serif; }

@import "styles/overwrite";
@import "styles/common-akk";
