//$primary: #ff662b;
$accent-500: #1E2D3E;
$accent-300: #344253;

$primary: #F07D00;
$accent: $accent-500;
$warn: var(--warn-color);

$color-black: #000000;
$color-black-900: #121212;
$color-white: #ffffff;

$color-grey-900: #2C2C2C;
$color-grey-800: #343434;
$color-grey-700: #4C4C4C;
$color-grey-600: #686868;
$color-grey-500: #969696;
$color-grey-400: #BDBDBD;
$color-grey-300: #E0E0E0;
$color-grey-200: #EEEEEE;
$color-grey-100: #F5F5F5;
$color-grey-50: #FAFAFA;

$color-background: $color-grey-50;
$color-background-gray: $color-grey-100;

$color-border-gray: $color-grey-300;
$color-text-black: $color-grey-900;
$color-text-dark-grey: $color-grey-700;
$color-text-grey: $color-grey-600;
$color-text-light-grey: $color-grey-500;

$color-background-accent: $accent-500;
$color-background-accent-op50: rgba(30, 45, 62, 0.5); // #1E2D3E

$color-red: #e53935;
$color-light-red: #e57373;
$color-green: #43a047;
$color-blue: #1e88e5;



$green-palette: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
);

$blue-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
);

$red-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
);
