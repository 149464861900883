
$breakpoints: (
  xs: 360px,
  sm: 600px,
  md: 1080px,
  lg: 1284px,
  xl: 1440px
);

@mixin respond-above($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid brakpoing: #{$breakpoint}';
  }
}

@mixin respond-below($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
  @warn 'Invalid brakpoing: #{$breakpoint}';
}
}

@mixin respond-between($lower, $upper) {

  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lover-value: map-get($breakpoints, $lower);
    $upper-value: map-get($breakpoints, $upper);
    @media (min-width: $lover-value) and (max-width: ($upper-value - 1)) {
      @content;
    }
  } @else {
  @warn 'Invalid brakpoing: #{$lower} and/or #{$upper}';
}
}
