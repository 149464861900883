@import "common";

.akk-bottom-table-button {
  margin: 16px;
}

.akk-restricted-container {
  max-width: 1480px;
  margin: 16px auto 48px;
  min-height: 900px;
}

.full-width {
  width: 100%;
}

form {
  .row {
    display: flex;
    flex-direction: row;
  }

  .col {
    flex: 1;
    margin-right: 20px;
  }
}

.admin {
  &-view-container {
    padding: 16px;
    min-height: 900px;
  }
  &-view-title {
    @extend .mat-h1

  }
  &-table-title {
    @extend .mat-h2
  }
}

.centered-small-view-container {
    display: flex;
    justify-content: center;
    margin: 62px 0;
    min-height: 400px;
}

.view-container {
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.centered {
    margin: auto;
  }

  &.spaced {
    padding-block: 24px;
  }

  &.big-spaced {
    padding-block: 64px;
  }

  &.restricted {
    max-width: 1160px;
  }

  &.restricted-small {
    max-width: 600px;
  }
}
.user {
  &-view-container {
    padding: 24px;
  }
}

.mat-icon.mat-icon-size-40 {
  font-size: 40px;
  line-height: 40px!important;
  width: 40px;
  height: 40px;
}

.mat-icon.mat-icon-size-32 {
  font-size: 32px;
  line-height: 32px!important;
  width: 32px;
  height: 32px;
}

.akk-page-content {
  background: $color-grey-50;
  color: $color-grey-900;
  padding: 64px 0;

  &.accent {
    background: $color-background-accent;
    color: $color-white;
  }

  &.grey {
    background: $color-grey-200;
    color: $color-grey-900;
  }

  @include respond-below(md) {
    padding: 32px 0;
    margin-bottom: 16px;
  }

  .title {
    @include akk-display-2;
    padding-bottom: 24px;
    @include respond-below(md) {
      @include akk-display-1;
      padding-bottom: 16px;
    }
  }
}

.akk-action-list {
  display: flex;
  justify-content: end;
  gap: 16px;
  padding: 32px 0;
}
