.ae-font .ae-picker-label {
  height: auto!important;
}


.mat-mdc-button {
  &.large {
    padding: 28px 16px;
    border-radius: 0;
  }
  &.mat-primary {
    color: white!important;
  }
}

.trainings-tabs {
  .mat-mdc-tab-label {
    color: #2C2C2C;
  }
  .mdc-tab {
    padding: 0;
    min-width: 0;
  }
}


.density-field {
  width: 100%;
  background-color: white;
  .mat-mdc-form-field-flex {
    height: 48px;
    align-items: center;
  }
  .mat-mdc-form-field-wrapper {
    padding: 0;
  }
  .mat-mdc-form-field-infix {
    input {
      margin-top: 8px;
    }
  }
  .mat-mdc-form-field-subscript-wrapper{
    display: none;
  }
}

a {
  color: #F07D00;
  text-decoration: none;
}

.grecaptcha-badge { visibility: hidden; }
