$defaultFontFamily: Inter, "Helvetica Neue", sans-serif;
$thinWeight: 300;
$normalWeight: 400;
$boldWeight: 500;

@mixin akk-button-text() {
  font: $boldWeight 16px/19.2px $defaultFontFamily;
}

@mixin akk-display-3() {
  font: $boldWeight 36px/43.2px $defaultFontFamily;
  letter-spacing: -0.02em;
}

@mixin akk-display-2() {
  font: $boldWeight 32px/38.4px $defaultFontFamily;
  letter-spacing: -0.02em;
}

@mixin akk-display-1() {
  font: $boldWeight 28px/35px $defaultFontFamily;
  letter-spacing: -0.02em;
}

@mixin akk-headline() {
  font: $boldWeight 24px/28.8px $defaultFontFamily;
  letter-spacing: -0.02em;
}

@mixin akk-title() {
  font: $boldWeight 18px/24.3px $defaultFontFamily;
  letter-spacing: -0.01em;
}

@mixin akk-subheading-2() {
  font: $boldWeight 16px/19.2px $defaultFontFamily;
}


@mixin akk-subheading-1() {
  font: $boldWeight 15px/18px $defaultFontFamily;
  letter-spacing: -0.02em;
}


@mixin akk-body-3() {
  font: $normalWeight 20px/28px $defaultFontFamily;
}

@mixin akk-body-2() {
  font: $normalWeight 18px/25.2px $defaultFontFamily;
}

@mixin akk-body-1() {
  font: $normalWeight 16px/24px $defaultFontFamily;
}

@mixin akk-body-3-bold() {
  font: $boldWeight 20px/28px $defaultFontFamily;
}

@mixin akk-body-2-bold() {
  font: $boldWeight 18px/25.2px $defaultFontFamily;
}

@mixin akk-body-1-bold() {
  font: $boldWeight 16px/24px $defaultFontFamily;
}

@mixin akk-body-0-bold() {
  font: $normalWeight 15px/19.2px $defaultFontFamily;
}

@mixin akk-caption() {
  font: $normalWeight 14px/21px $defaultFontFamily;
}
